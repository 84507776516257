<template>
    <div class="modal-profile-item-selector bg-main">
        <div class="back-button-wrapper" @click="onClickClose()">
            <i class="back-button material-icons">chevron_left</i>
        </div>
        <div class="top">
            <div class="title text-center f-18" v-html="title" />
        </div>
        <div class="input-wrapper items-center">
            <i class="material-icons i-search">search</i>
            <input ref="searchValue" v-model="searchValue" @input="onKeydown" :placeholder="placeholder" />
            <i v-if="searchValue" @click="searchValue = null" class="material-icons">cancel</i>
        </div>
        <div class="result-wrapper" v-if="searchResults">
            <div class="result" v-for="(res, idx) in searchResults" :key="idx">
                <span class="c-black" v-html="`${res.college_name || ''} ${res.name}`" />
                <span @click="onClickBlock(res)" class="c-primary f-medium" v-html="$translate('BLOCK')" />
            </div>
        </div>
    </div>
</template>

<script>
import debounce from '@/modules/debounce'
import profileService from '@/services/profile'
import userService from '@/services/user'

export default {
    name: 'ModalCollegeSearch',
    data: () => ({
        searchValue: null,
        searchResults: null,
    }),
    props: ['options'],
    computed: {
        title() {
            if (this.blockType === 'major') {
                return this.$translate(`SELECT_COLLEGE_MAJOR_BLOCK`)
            } else {
                return this.$translate('SELECT_COMPANY_BLOCK')
            }
        },
        placeholder() {
            if (this.blockType === 'major') {
                return this.$translate(`SEARCH_COLLEGE_MAJOR_BLOCK`)
            } else {
                return this.$translate('SEARCH_COMPANY_BLOCK')
            }
        },
        blockType() {
            return this.options.blockType
        },
    },
    methods: {
        onClickClose() {
            this.$nativeBridge.postMessage({
                action: 'setBackgroundColor',
                value: {
                    top: '#FAF0E1',
                    bottom: '#FFF9EF',
                },
            })
            this.$emit('close')
        },
        onKeydown() {
            setTimeout(() => {
                this.searchValue = this.$refs.searchValue.value
                this.search(this.searchValue)
            }, 200)
        },
        search: debounce(async function () {
            if (this.blockType === 'major') {
                this.searchResults = await profileService.majors({ name: this.searchValue })
            } else {
                this.searchResults = await profileService.companies({ name: this.searchValue })
            }
        }, 200),
        async onClickBlock(resp) {
            try {
                if (this.blockType === 'major') {
                    const name = `${resp.college_name || ''} ${resp.name}`

                    const { msg, res } = await userService.blockProfile(this.$store.getters.me.id, {
                        from: resp.from,
                        name: `${this.options.university} ${name}`,
                        type: 'major',
                    })

                    const { data } = await userService.getBlockProfile(this.$store.getters.me.id, 'major')
                    if (res === 'fail') this.$toast.error(msg)
                    else this.$toast.success(msg)

                    this.$store.commit('setBlockedCollege', data)
                } else if (this.blockType === 'company') {
                    const name = resp.name

                    const { msg, res } = await userService.blockProfile(this.$store.getters.me.id, {
                        name,
                        type: 'company',
                    })

                    const { data } = await userService.getBlockProfile(this.$store.getters.me.id, 'company')
                    if (res === 'fail') this.$toast.error(msg)
                    else this.$toast.success(msg)

                    this.$store.commit('setBlockedCompany', data)
                }
            } catch (e) {
                this.$toast.error('처리 중 오류가 발생했습니다')
            }
        },
    },
    created() {
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: '#FFF9EF',
        })
    },
}
</script>
